import React, {useState} from "react";
import {
    Box,
    Button,
    CssBaseline,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Stack,
    Toolbar,
    Tooltip,
} from "@mui/material";
import {Link, NavLink, Outlet, useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {auth} from "../../utils/firebaseApp";
import {signOut} from "firebase/auth";
import {toast} from "react-toastify";
import {Header} from "../../components/main-header/HeaderElla";
import {
    IconAppWindow,
    IconCode,
    IconLogout,
    IconSparkles,
    IconCategory2,
    IconReport,
    IconMessage2,
    IconSettings,
    IconChartPie
} from "@tabler/icons-react"
import logoImg from "../../assets/logo/Ella.svg";

export const drawerWidth = 280;

const navLinks = [
    {path: "/dashboard", name: "Dashboard", startIcon: <IconAppWindow/>},
    {path: "/surveys", name: "My surveys", startIcon: <IconCategory2/>},
    {path: "/summaries", name: "Summaries", startIcon: <IconChartPie/>},
    {path: "/reports", name: "Reports", startIcon: <IconReport/>},
    {path: "/analysis", name: "Responses", startIcon: <IconMessage2/>},
    {path: "/survey-designer", name: "AI Survey Designer", startIcon: <IconSparkles/>},
    {path: "/api", name: "Advanced Access", startIcon: <IconCode/>},
    {path: "/settings", name: "Settings", startIcon: <IconSettings/>},
    // {path: "/create-survey", name: "Create Survey"},
];

export const MainLayout = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <Box
            sx={{
                display: "flex",
                height: "100dvh",
                overflowX: "hidden",
            }}
        >
            <CssBaseline/>
            <Header toggleMenu={setIsOpen} open={isOpen} />
            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
            <Stack
                direction={"column"}
                component="main"
                sx={{
                    flexGrow: 1,
                    pl: {xs: 3, md: "56px"},
                    pr: 3,
                    height: "100%",
                    maxHeight: "100%",
                    marginTop: "0px",
                }}
            >
                <Toolbar />
                <Box
                    sx={{
                        height: "100%",
                        p: {xs: 0, md: 3},
                    }}
                    id={"box-with-children"}
                >
                    <Outlet/>
                </Box>
            </Stack>
        </Box>
    );
};

const Sidebar = ({isOpen, setIsOpen}) => {
  const navigate = useNavigate();

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <Stack
        direction={"column"} gap={0}
        className={`sidebar`}
        sx={{
          position: "fixed",
          bgcolor: "background.paper",
          zIndex: 998,
          maxWidth: `${drawerWidth}px`,
          height: '100dvh',
          display: {xs: "none", md: "flex"}
        }}
      >
        <DrawerItems logoutHandle={logout} handleClose={() => setIsOpen(false)}/>
      </Stack>
      <Drawer
        variant={"temporary"}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        className={"sidebar active"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          border: 0,
          height: "100%",
          display: {xs: "flex", md: "none !important"},
          flexDirection: "column", gap: 3,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            border: 0,
          },
        }}
      >
        <DrawerItems logoutHandle={logout} handleClose={() => setIsOpen(false)}/>
      </Drawer>
    </>
  )
}

const DrawerItems = ({logoutHandle, handleClose}) => {
    return (
        <>
            <Toolbar>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{display: {xs: "flex", md: 'none'} }}
              >
                <img src={logoImg} alt={"Logo"} height={60}/>
              </Stack>
            </Toolbar>
            <Box sx={{mx: 2, mt: 3, display: {xs: "inline-flex", md: "none"}}}>
                <Button
                    component={Link}
                    to={"/create-survey"}
                    fullWidth
                    sx={{p: 1}}
                    variant={"contained"}
                    className={"gradient-btn"}
                    startIcon={<AddIcon/>}
                >
                    Create Survey
                </Button>
            </Box>
            <List sx={{border: 0, flex: 1}}>
                {navLinks.map((item, index) => (
                    <ListItemButton key={item?.path}
                                    className={"sidebar-item"}
                                    component={NavLink} to={item.path}
                                    onClick={handleClose}
                                    sx={{
                                        px: 2,
                                        color: "secondary.dark",
                                        "&.active": {color: "#5271FF !important", fontWeight: 600}
                                    }}
                    >
                        {item.startIcon ? <ListItemIcon
                            sx={{minWidth: 0, pr: 1, color: "inherit"}}>{item?.startIcon}</ListItemIcon> : null}
                        <ListItemText
                            className={"sidebar-item-text"}
                            primaryTypographyProps={{
                                sx: {
                                    fontWeight: "inherit",
                                    color: "inherit",
                                    padding: "0px",
                                    fontSize: "1.02em",
                                    width: 'auto',
                                    textWrap: "nowrap",
                                    wordWrap: "nowrap",
                                },
                            }}
                            primary={item.name}
                        />
                        {item.endIcon ? <ListItemIcon sx={{minWidth: 'auto', color: "inherit"}}>
                            <Tooltip title={'Experimental feature'}>
                                {item?.endIcon}
                            </Tooltip>
                        </ListItemIcon> : null}
                    </ListItemButton>
                ))}
            </List>
            <List>
                <ListItemButton className={"sidebar-item"} sx={{mt: "auto !important", px: 2, color: "secondary.dark"}}
                                onClick={logoutHandle}>
                    <ListItemIcon sx={{color: "secondary.dark"}}><IconLogout/></ListItemIcon>
                    <ListItemText
                        className={"sidebar-item-text"}
                        primaryTypographyProps={{
                            sx: {
                                color: "secondary.dark",
                                padding: "0px",
                                fontSize: "1.02em",
                                width: 'auto',
                            },
                        }}
                        primary={"Logout"}
                    />
                </ListItemButton>
            </List>
        </>
    );
};

