import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Stack, Typography} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import {calculateWordFrequencyAndReturnAsObject} from "../../../../utils/sharedFunctions";
import {CardItem} from "../../../../components/card/CardItem";
import {SwitchButtons} from "../../../../components/switch-buttons/SwitchButtons";
import {useNavigate} from "react-router-dom";
import {ColumnChart} from "./ColumnChart";
import {EnglishNativeLanguage} from "../../../../utils/english-native-language";


const GraphTypes = {WORDCLOUD: "wordcloud", BAR: "bar"}

const AutoType = {
    AUTO: "word cloud",
    MANUAL: "topics"
}

export const VoiceQuestionGraph = ({
                                       question,
                                       answers,
                                       title,
                                       subtitle,
                                       secondaryAction,
                                       collapsable
                                   }) => {
    const [type, setType] = useState(GraphTypes.WORDCLOUD);
    const [autoType, setAutoType] = useState(AutoType.AUTO);
    const [topicsCounts, setTopicsCounts] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!answers) return
        const topicsCounts = {}
        answers.forEach(answer => {
            if (answer.topics) {
                answer.topics.forEach(topic => {
                        topicsCounts[topic] = topicsCounts[topic] ? topicsCounts[topic] + 1 : 1
                    }
                )
            }
        })
        setTopicsCounts(topicsCounts)
    }, [answers, question])

    return (
        <CardItem
            title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>{title}</Typography>}
            subheader={<Typography fontSize={12} variant={"secondary2"} color={"#858585"}>{subtitle}</Typography>}
            key={question.uid} collapsable={collapsable}
            secondary={secondaryAction ? secondaryAction(question.uid) : null}
        >
                <Stack direction={"column"} gap={0}>
                    <SwitchButtons
                        // title={'Classification'}
                        firstType={AutoType.AUTO}
                        secondType={AutoType.MANUAL}
                        onChange={(newType) => setAutoType(newType)}
                        selectedType={autoType}
                    />
                  <Box sx={{flex: 1, pt: 2}}>
                    {(type === GraphTypes.WORDCLOUD
                        && autoType === AutoType.AUTO)
                      && <Box className={"border-shadow"} sx={{borderRadius: 3}} >
                        <WordCloudChart question={question} answers={answers}/>
                      </Box>
                    }

                    {autoType === AutoType.MANUAL && !topicsCounts &&
                      <Button
                        onClick={() => {
                          navigate('analysis-page')
                        }}
                        sx={{textAlign: 'center', width: '100%', display: 'block'}} variant={'text'}>
                        Set the topics with AI
                      </Button>
                    }
                    {autoType === AutoType.MANUAL
                      && topicsCounts
                      && <ColumnChart open={autoType === AutoType.MANUAL} options={topicsCounts}/>
                    }
                  </Box>
                </Stack>

        </CardItem>
    )
}

const WordCloudChart = ({answers, question}) => {
    const [words, setWords] = useState([]);
    const [colors, setColors] = useState({});

    useEffect(() => {

      if (answers && answers?.length && question) {
        const sausageString = answers.map(answer => answer?.translation || answer.text).join(' ')
        const wordFrequencies = calculateWordFrequencyAndReturnAsObject(sausageString) || [];
        setWords(wordFrequencies);
        const newColors = {};
        for (const word of wordFrequencies) {
          const value = word.value;
          if (!newColors[value]) {
            newColors[value] = getRandomHexColor();
          }
        }
        setColors(newColors);
      }
    }, [answers, question]);
    // map over array of answer objects and join them into a string based on text key

  function getRandomHexColor() {
    let hexColor;
    do {
      hexColor = Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');
    } while (hexColor === 'ffffff');
    return `#${hexColor}`;
  }

    return (
        <ReactWordcloud
            words={words}
            options={{
                rotations: 0,
                fontSizes: [15, 70],
                fontFamily: "Poppins"
            }}
            callbacks={{
              getWordColor: (word) =>  colors[word.value] || getRandomHexColor(),

        }}
        />
    )
}
