import { useEffect } from "react";
import {Box, Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import {Add, DeleteOutline, DragIndicator} from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const OptionsQuestions = ({ question, onUpdate }) => {
  useEffect(() => {
    if (!question?.options || question?.options.length === 0) {
      onUpdate("options", [{ option: "", checked: false }]);
    }
  }, [question]);

  const onUpdateOption = (index, value) => {
    const options = question?.options ? [...question?.options] : [];
    if (index < options.length) {
      options[index].option = value;
      onUpdate("options", options);
    }
  };

  const onRemoveOption = (index) => {
    let options = question?.options ? [...question?.options] : [];
    if (index < options.length) {
      options = options?.filter((_, ind) => ind !== index);
      onUpdate("options", options);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(question.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onUpdate("options", items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="options">
        {(provided) => (
          <Stack
            direction={"column"}
            gap={1}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {question?.options?.map((optionItem, index) => {
              return (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      gap={2}
                      alignItems={"center"}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Box
                        {...provided.dragHandleProps}
                      >
                        <DragIndicator
                          sx={{ cursor: "grab" }}
                        />
                      </Box>
                      <TextField
                        variant={"standard"}
                        placeholder={`Enter option ${index + 1}`}
                        value={optionItem?.option}
                        onChange={(e) =>
                          onUpdateOption(index, e?.target?.value)
                        }
                        sx={{ flex: 1 }}
                      />
                      <IconButton onClick={() => onRemoveOption(index)}>
                        <DeleteOutline />
                      </IconButton>
                    </Stack>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            {question?.options?.length >= 6 && <Typography variant={"body2"}>Heads up, adding too many options can be overwhelming to respondents!</Typography>}
            <Box sx={{textAlign: "center"}}>
              <Button
                startIcon={<Add />} className={"gradient-outlined-btn"} variant={"outlined"}
                onClick={() => onUpdate("options", [...question?.options, { option: "", checked: false }])}
              >
                Add Option
              </Button>
            </Box>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};
