import React, {useEffect, useState} from "react";
import {Button, Stack, Typography} from "@mui/material";
import {CardItem} from "../../../../components/card/CardItem";
import Box from "@mui/material/Box";

export const ImageQuestionPreview = ({question, answers, title, subtitle, index, secondaryAction, collapsable}) => {
    const [photos, setPhotos] = useState([]);
    const [size, setSize] = useState(10);

    useEffect(() => {
        let images = [];
        if (answers?.length) {
            for (const answer of answers) {
                if (answer.photoUrls?.length) {
                    images = [...images, ...answer.photoUrls]
                } else if (answer?.photos?.length) {
                    images = [...images, ...answer.photos]
                }
            }
        }
        setPhotos(images)
    }, [answers])

    return (
        <CardItem
            title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>{title}</Typography>}
            subheader={<Typography fontSize={12} variant={"subtitle2"} color={"#858585"}>{subtitle}</Typography>}
            secondary={secondaryAction ? secondaryAction(question.uid) : null} collapsable={collapsable}
        >
            <Stack direction={"row"} gap={1} sx={{my: 2}} justifyContent={"start"} alignItems={"center"} flexWrap={"wrap"}>
                {
                    photos?.slice(0, size)?.map(photo => {
                        return <img key={photo} src={photo}
                                    style={{width: "auto", maxHeight: "180px", height: "auto"}}
                                    alt={'preview img'}/>
                    })
                }
            </Stack>
            {photos?.length > size &&
              <Box sx={{textAlign: "center"}}>
                  <Button onClick={() => setSize(prev => prev + 10)}>
                      Load More
                  </Button>
              </Box>
            }
        </CardItem>
    )
}
