import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import MapComponent from "../../../components/Map/MapComponent";
import React, {useEffect, useState} from "react";
import QuestionTitle from "./QuestionTitle";

export const MapInputQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  return (
    <>
      <QuestionTitle title={answer?.question || "-"} />

      <MapComponent
        questionObject={answer} userCanSetPOIs={true}
        setPois={(newPois) => setAnswer(prev => ({...prev, pois: newPois}))} pois={answer?.pois || []}
        showUserDot={true}
      />

    </>
  )
}