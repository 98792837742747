import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../utils/AppContext";
import {animals, colors, uniqueNamesGenerator} from "unique-names-generator";
import {v4 as uuid} from "uuid";
import {updateAPIkeysInDB} from "../../../utils/sharedFunctions";
import {toast} from "react-toastify";
import {
  Button,
  IconButton, Input,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CodeSampleTabs from "./CodeSamples";
import {CopyAll, DeleteOutline, Visibility, VisibilityOff} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import {IconPlus} from "@tabler/icons-react";


export const ApiKeys = ({survey}) => {
  const {state: {currentUser}, dispatch} = useContext(AppContext);
  const [apiList, setApiList] = useState([]);
  const [selectedApi, setSelectedApi] = useState(null);

  useEffect(() => {
    if (currentUser?.apiKeys?.length) setApiList(currentUser.apiKeys || []);
  }, [currentUser]);

  const generateApi = async () => {
    const name = uniqueNamesGenerator({
      dictionaries: [colors, animals],
      style: "lowerCase",
      separator: "-",
    });

    const apiKey = {
      name: name,
      key: uuid(),
    };

    try {
      const apiListUpdated = [...apiList, apiKey];
      await updateAPIkeysInDB(apiListUpdated, currentUser);
      setApiList(apiListUpdated);
      currentUser.apiKeys = apiListUpdated;
      dispatch({currentUser: currentUser});
      toast.success("Your api key has been generated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteApiKey = (item) => {
    const items = [...apiList];
    const apiListFiltered = items.filter((i) => i?.key !== item?.key);

    setApiList(apiListFiltered);
    currentUser.apiKeys = apiListFiltered;
    dispatch({currentUser: currentUser});
    toast.success("Your api key has been deleted successfully");
  };

  return (
    <Stack
      direction={"column"}
      gap={2}
      flexWrap={"wrap"}
      sx={{width: "100%", py: 2, marginTop: "10px", marginBottom: '20px'}}
    >
      <Typography variant={'h4'}>API Keys</Typography>
      <Typography variant={'body1'}>Generate API keys to access your data
        programmatically </Typography>

      <Stack direction={"column"} gap={1} sx={{borderRadius: 5, px: 2}} className={"border-shadow"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell sx={{width: "65%"}}>Key</TableCell>
              <TableCell>
                <Button
                  sx={{bgcolor: "#FFF", height: '35px', textWrap: "nowrap", display: {xs: "none", sm: "flex"} }}
                  onClick={generateApi}
                  variant={"outlined"}
                >
                  Generate API key
                </Button>
                <IconButton
                  onClick={generateApi}
                  size={"small"} color={"primary"}
                  sx={{display: {xs: "flex", sm: "none"} }}
                >
                  <IconPlus size={20}/>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiList.map((item) => {
              return (
                <ApiItem
                  key={item?.key}
                  api={item} selectedApi={selectedApi}
                  onSelect={(api) => setSelectedApi(api)}
                  onDelete={() => deleteApiKey(item)}
                />
              );
            })}
          </TableBody>
        </Table>
      </Stack>

      <Typography variant={'h4'}>Code Samples</Typography>
      <Typography variant={'body1'}>Use your API keys by following the code samples below</Typography>
      {currentUser?.apiKeys?.length && (
        <CodeSampleTabs
          apiKey={
            selectedApi?.key || "<api-key>"
          }
          surveyUid={survey?.uid || "<survey-uid>"}
        />
      )}
    </Stack>
  )
}


const ApiItem = ({api, selectedApi, onSelect, onDelete}) => {
  const [showApi, setShowApi] = useState(false);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(api?.key);
      toast.success("Your api key has been copied successfully");
    } catch (error) {
      toast.success("Error copying api key");
    }
  };

  return (
    <TableRow>
      <TableCell padding={"checkbox"}><Checkbox checked={api?.key === selectedApi?.key} onChange={(e, checked) => onSelect(checked ? api : null)} /></TableCell>
      <TableCell sx={{wordWrap: "nowrap", textWrap: "nowrap", pr: 3, fontSize: "1.1rem"}}>{api?.name}</TableCell>
      <TableCell sx={{width: "100%"}}>
        <Input
          disableUnderline
          value={api?.key}
          type={showApi ? "text" : "password"}
          readOnly={true} fullWidth
          sx={{fontSize: "1.3rem", letterSpacing: "0.1rem"}}
        />
      </TableCell>
      <TableCell>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowApi(!showApi)}
            edge="end"
          >
            {showApi ? <Visibility/> : <VisibilityOff/>}
          </IconButton>
          <IconButton
            aria-label="toggle password visibility"
            onClick={copyToClipboard}
            edge="end"
          >
            <CopyAll/>
          </IconButton>
          <IconButton
            onClick={onDelete}
          >
            <DeleteOutline/>
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
