import React, {useEffect, useState} from "react";
import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {enums} from "../../../utils/enums";
import axios from "axios";
import {toast} from "react-toastify";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {Response} from "./Response";
import {QuestionTypes} from "../../create-project-page-new/enums";


export const Responses = ({responses, question}) => {
    const [sortBy, setSortBy] = useState(["timestamp"]);
    const [sentiment, setSentiment] = useState([]);
    const [filteredResponses, setFilteredResponses] = useState([]);

    useEffect(() => {
        if (responses?.length) {
            setSortBy(["timestamp"]);
            setSentiment([]);
            filterData(responses);
        }
    }, [responses]);

    const filterData = (items) => {
        if (sentiment?.length > 0) {
            // TODO (Here we need to make a filter function)
        }
        if (sortBy?.length > 0) {
            //TODO (sort function)
        }
        console.log(items)
        setFilteredResponses(items);
    };

    async function getTopicsAndClassifyAnswers() {
        toast.info('Classifying answers by topic using AI')
        let topics;

        const createTopicsUrl = process.env.REACT_APP_RENDER_URL + 'create_topics';
        const classifyResponsesUrl = process.env.REACT_APP_RENDER_URL + 'classify_responses';
        const bearerToken = process.env.REACT_APP_RENDER_TOKEN;

        try {
            const response = await axios.post(createTopicsUrl, {
                survey_responses: filteredResponses.map((response) => response?.translation || response.text),
                num_topics: 5
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            topics = response.data;

            const classifyResponses = await axios.post(classifyResponsesUrl, {
                survey_responses: filteredResponses.map((response) => response?.translation || response.text),
                topics_list: topics
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            const classifiedResponses = classifyResponses.data;
            filteredResponses.forEach((response, index) => {
                response.topics = classifiedResponses[index];
            });
            setFilteredResponses([...filteredResponses])

            const batch = writeBatch(db);
            filteredResponses.forEach((response) => {
                const responseRef = doc(db, "answers", response.uid);
                batch.update(responseRef, {topics: response.topics});
            })
            await batch.commit();
            toast.success("Classified successfully");
        } catch (error) {
            console.log(error);
            toast.error("Classification failed");
        }
    }

    return (
        question
        && question?.type
        && [QuestionTypes.TEXT, QuestionTypes.IMAGE, QuestionTypes.VOICE, QuestionTypes.SLIDER, QuestionTypes.DROPDOWN, QuestionTypes.CHECKBOXES, QuestionTypes.MULTICHOICE].includes(question.type)
        && (
            <Box sx={{paddingBottom: "150px"}}>
                {question
                    && question?.type
                    && [QuestionTypes.TEXT, QuestionTypes.VOICE].includes(question.type)
                    && <Box sx={{pb: 2}}>
                        <Typography variant={'h4'}>Analyze answers</Typography>
                        <Button variant={'contained'} sx={{width: '220px', mt: 2}}
                                onClick={() => getTopicsAndClassifyAnswers()}>
                            Classify by topic using AI
                        </Button>
                    </Box>}

                <Divider sx={{mt: 2, mb: 2}}/>

                {filteredResponses.length > 0
                    ? <Stack direction={"column"} gap={2}>
                        {filteredResponses?.map((filteredResponse) => (
                            <Response response={filteredResponse}/>
                        ))}
                    </Stack>
                    : <Typography variant={'h6'}>No responses yet...</Typography>
                }
            </Box>
        )
    );
};
