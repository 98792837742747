import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../utils/AppContext";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import CreateReportModal from "./CreateReportModal";
import {deleteDoc, doc} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";
import {SurveyReportItem} from "./survey-report-item";
import {CreateSurveyBanner} from "../../components/shared-components/banner/create-survey-banner";

const ReportsPage = () => {
  const {state: {currentUser, surveys, reports}, dispatch} = useContext(AppContext);
  const [surveyReports, setSurveyReports] = useState([]);
  const [openCreateReportModal, setOpenCreateReportModal] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [openSurveyQrCode, setOpenSurveyQrCode] = useState(false);
  const [activeReport, setActiveReport] = useState(null);
  const [editReport, setEditReport] = useState(null)

  useEffect(() => {
    if (surveys?.length) {
      const newSurveys = surveys?.map(item => {
        const surveyReportItems = reports?.filter(i => i?.surveyUid === item?.uid)?.sort((a, b) => a?.dateCreated - b?.dateCreated);
        return {...item, reports: surveyReportItems || []}
      });
      setSurveyReports(newSurveys);
    }
  }, [reports, surveys])

  async function deleteReport(reportUid) {
    try {
      const response = await confirm(
        {
          title: `Are you sure you want to delete this report?`,
          // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
        },
        confirmDialogOptions,
      );
      if (response === false) return;

      await deleteDoc(doc(db, "reports", reportUid));
      const newReports = reports.filter(report => report.uid !== reportUid);
      dispatch({reports: [...newReports]})
      toast.success('Report removed')
    } catch (e) {
      console.error("Error removing document: ", e);
      toast.error('Error removing document')
    }
  }

  if (surveyReports.length === 0) {
    return (
      <Stack
        direction={"column"}
        gap={2} maxWidth={"lg"}
        sx={{mx: {xs: 2, md: "auto"}}}
      >
        <TitleDate title="Survey Reports" big/>
        <CreateSurveyBanner/>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction={"column"} maxWidth={"lg"} sx={{mx: {xs: 2, md: "auto"}}}>
        <TitleDate title="Survey Reports"/>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: 600}}></TableCell>
              <TableCell sx={{fontWeight: 600}}>Name</TableCell>
              <TableCell sx={{fontWeight: 600}}>Languages</TableCell>
              <TableCell sx={{fontWeight: 600}}>Created</TableCell>
              {/*<TableCell sx={{fontWeight: 600}}>Active</TableCell>*/}
              <TableCell sx={{fontWeight: 600}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveyReports?.map(survey => (
              <SurveyReportItem
                key={survey?.uid}
                survey={survey}
                onCreateReport={() => {
                  setOpenCreateReportModal(true);
                  setActiveSurvey(survey);
                }}
                onEditReport={(report) => {
                  setOpenCreateReportModal(true);
                  setActiveSurvey(survey);
                  setEditReport(report);
                }}
                selectActiveReport={setActiveReport}
                setOpenSurveyQrCode={setOpenSurveyQrCode}
                deleteReport={deleteReport}
              />
            ))}

          </TableBody>
        </Table>
      </Stack>
      <CreateReportModal open={openCreateReportModal}
                         handleClose={() => {
                           setEditReport(null);
                           setOpenCreateReportModal(false);
                         }}
                         editReport={editReport}
                         activeSurvey={activeSurvey}/>
    </>
  );
}

export default ReportsPage;
