import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import {AppContext} from "../../utils/AppContext";
import {Button, IconButton, Stack, TextField, useTheme} from "@mui/material";
import uuid from "react-uuid";
import {toast} from "react-toastify";
import {auth, db} from "../../utils/firebaseApp";
import GridLayout from "react-grid-layout";
import {doc, setDoc} from "firebase/firestore";
import {Close, DeleteOutline, SaveOutlined} from "@mui/icons-material";
import {QuestionTypes} from "../create-project-page-new/enums";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  height: '90vh',
  bgcolor: 'background.paper',
  px: 2, py: "10px",
  borderRadius: 5
};

export default function CreateReportModal({open, handleClose, activeSurvey, editReport}) {
  const [width, setWidth] = useState(1200); // default width
  const containerRef = useRef(null); // reference for the parent container

  const {
    state: {reports, questionObjects, answerObjects},
    dispatch
  } = useContext(AppContext);

  const [uniqueQuestionObjectsForSurveyToDisplay, setUniqueQuestionObjectsForSurveyToDisplay] = useState([]);
  const [questionsToDisplayInAReport, setQuestionsToDisplayInAReport] = useState([]);
  const [reportName, setReportName] = useState("New Report " + new Date().toLocaleDateString());
  const theme = useTheme();

  // Update layout width on resize or load
  useEffect(() => {
    if (open) {
      const updateWidth = () => {
        if (containerRef.current) {
          setWidth(containerRef.current.offsetWidth); // set width based on the container div
        }
      };

      updateWidth(); // update the width when the component mounts
      window.addEventListener('resize', updateWidth); // update the width on window resize

      return () => window.removeEventListener('resize', updateWidth); // clean up on unmount
    }
  }, [open, containerRef.current]);

  useEffect(() => {
    if (!activeSurvey) return;
    let allQuestionObjectsForSurvey = questionObjects.filter(
      (question) => question?.surveyUid === activeSurvey?.uid,
    );
    let uniqueQuestionObjectsForSurvey = allQuestionObjectsForSurvey.filter(
      (question) =>
        question?.languageObject.BCP47 === activeSurvey?.languages[0].BCP47,
    ).filter(question => question?.type !== QuestionTypes.SECTION);
    if (uniqueQuestionObjectsForSurvey?.length) {
      const questions = uniqueQuestionObjectsForSurvey?.map(question => ({...question, position: {i: question?.uid, x: 0, y: 0, w: 12, h: 8} }))
      console.log("UNIQUE", questions);
      setUniqueQuestionObjectsForSurveyToDisplay(questions);
      setQuestionsToDisplayInAReport(questions)
    }
  }, [activeSurvey, answerObjects])

  useEffect(() => {
    if (editReport?.uid && questionObjects?.length) {
      setReportName(editReport?.name);
      const questionToDisplay = [];
      for (const question of questionObjects) {
        if (editReport?.questions?.includes(question?.uid)) {
          const position = editReport?.positions?.find(i => i?.questionUid === question?.uid)?.position || {x: 0, y: 0, w: 12, h: 8, i: question?.uid, };
          questionToDisplay.push({...question, position});
        }
      }
      console.log("Edit report", questionToDisplay)
      setQuestionsToDisplayInAReport(questionToDisplay);
    }
  }, [editReport, questionObjects])

  function getRemoveFromReport(questionUid) {
    function removeVisualFromReport() {
      setQuestionsToDisplayInAReport(questionsToDisplayInAReport.filter(question => question.uid !== questionUid))
    }

    return (
      <IconButton variant={'text'} key={questionUid} sx={{color: theme.palette.error.main, mt: 1, mr: 1}}
                  onClick={(e) => {
                    e?.stopPropagation();
                    removeVisualFromReport();
                  }}>
        <DeleteOutline/>
      </IconButton>
    )
  }

  async function saveReport() {
    try {
      let report = {
        surveyUid: activeSurvey.uid,
        name: reportName,
        questions: questionsToDisplayInAReport.map(question => question.uid),
        positions: questionsToDisplayInAReport?.map(question => ({
          questionUid: question?.uid,
          position: question?.position || {i: question?.uid, x: 0, y: 0, w: 12, h: 8}
        })),
        uid: editReport?.uid || uuid(),
        userUid: auth.currentUser.uid,
        dateCreated: new Date().getTime()
      }
      await setDoc(doc(db, "reports", report.uid), report)
      let newReports = []
      if (editReport) {
        newReports = [...reports]?.map(i => {
          if (i?.uid === editReport?.uid) {
            return {...report}
          }
          return {...i};
        })
      } else {
        newReports = [...reports, {...report}];
      }
      dispatch({reports: [...newReports]})

      handleClose()
    } catch (e) {
      console.log(e)
      toast.error("Error saving report")
    }
  }

  function resetReport() {
    setQuestionsToDisplayInAReport(uniqueQuestionObjectsForSurveyToDisplay)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack direction={"column"} gap={"10px"} sx={style}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{px: 2}}>
          <Box>
            <TextField
              id="standard-basic"
              variant="standard"
              value={reportName}
              onChange={(e) => {
                setReportName(e.target.value)
              }}
              inputProps={{
                sx: {fontSize: 28, color: "#5271FF"},
              }}
              sx={{fontSize: 28, color: "#5271FF"}}
            />
            <Typography variant={'body2'}>
              Choose the visualizations you want to have on this report and remove all the ones you don't
              need.
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}><Close/></IconButton>
          </Box>
        </Stack>
        <Stack ref={containerRef} direction={"column"} gap={1} sx={{flex: 1, overflowY: "auto", overflowX: "hidden"}}>
          <GridLayout
            className="layout"
            isDraggable={true}
            isResizable={true}
            cols={12}
            style={{minHeight: "300%"}}
            rowHeight={50}
            width={width}
            onLayoutChange={(layout) => {
              setQuestionsToDisplayInAReport(prev => {
                const items = [...prev];
                for (const layoutItem of layout) {
                  const index = items?.findIndex(i => i?.uid === layoutItem?.i);
                  if (index >= 0) {
                    items[index].position = {i: layoutItem?.i, x: layoutItem.x, y: layoutItem.y, w: layoutItem.w, h: layoutItem.h};
                  }
                }
                return items;
              })
            }}
          >
            {questionsToDisplayInAReport
              ?.map((item, index) => {
                console.log("item", item)
                  return (
                    <Box key={`${item?.uid}`} className={"widget"}
                         sx={{border: "0 !important", cursor: "move"}}
                         data-grid={{
                           x: item?.position?.x || 0,
                           y: item?.position?.y || 0,
                           w: item?.position?.w || 4,
                           h: item?.position?.h || 5,
                           minW: 2,
                           minH: 1
                         }}
                    >
                      <QuestionGraphWrapper
                        question={item}
                        index={index + 1}
                        removeFromReport={getRemoveFromReport}
                      />
                    </Box>
                  )
                }
              )}
          </GridLayout>
        </Stack>
        <Stack direction={'row'} justifyContent={"flex-end"} spacing={2} sx={{px: 2}}>
          <Button className={"gradient-outlined-btn"} sx={{border: 1, px: 2,}} onClick={() => resetReport()}>
            Reset
          </Button>

          <Button className={"gradient-btn"} sx={{px: 2, width: "auto"}} startIcon={<SaveOutlined/>}
                  onClick={() => saveReport()}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
