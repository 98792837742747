import {
  Box,
  Button, Chip, IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {AutoFixHigh, CopyAll, DeleteOutline, Download, RemoveOutlined} from "@mui/icons-material";
import React, {useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {collection, doc, onSnapshot, orderBy, query, where, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {v4 as uuid} from "uuid";
import {saveAsCsv} from "../../../utils/sharedFunctions";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import {copyToClipboard} from "../../../utils/functions";
import {grey} from "@mui/material/colors";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../components/ConfirmDialog";
import {AddTokensModal} from "./add-tokens";


export const SurveyTokens = ({survey}) => {
  const [addTokens, setAddTokens] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6); // Rows per page
  const [selectedTokens, setSelectedTokens] = useState({});

  useEffect(() => {
    if (survey?.uid) {
      setSelectedTokens({});
      setPage(0)
      setRowsPerPage(6);
      setTokens([]);

      const tokensQuery = query(
        collection(db, "tokens"),
        where("surveyUid", "==", survey?.uid)
      );

      const unsubscribe = onSnapshot(tokensQuery, (querySnapshot) => {
        let newTokens = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newTokens.push(data);
        });
        console.log("tokens", newTokens)
        setTokens(newTokens?.sort((a, b) => b?.dateCreated - a?.dateCreated));
      });

      return () => unsubscribe();
    }
  }, [survey])

  const generateUrl = (token) => {
    if (!token || !survey) return "";
    const surveyUrl = `${window.location.origin}/survey/${survey.uid}`;
    return `${surveyUrl}?token=${token}`;
  }

  const downloadTokens = () => {
    const csvData = [];
    for (const token of Object.keys(selectedTokens)) {
      const url = generateUrl(token);
      if (url) {
        csvData.push(url);
      }
    }
    try {
      if (csvData?.length) {
        const csv = "Link\n" + csvData.join("\n");
        const blob = new Blob([csv], {type: "text/csv"});
        saveAsCsv(blob, `unique_links_${survey?.surveyName}.csv`);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to download csv file");
    }
  }

  const copyTokenUrl = (selectedToken) => {
    if (selectedToken?.token && survey?.uid) {
      copyToClipboard(generateUrl(selectedToken?.token))
    }
  }

  const deleteTokens = async (tokenItems) => {
    try {
      const response = await confirm(
        {
          title: `Are you sure you want to delete token?`,
          // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
        },
        confirmDialogOptions,
      );
      if (response === false) return;

      const batch = writeBatch(db);

      for (const token of tokenItems) {
        batch.delete(doc(db, "tokens", token));
      }
      await batch.commit()
    } catch (error) {
      toast.error("Failed to delete tokens")
    }

  }

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page after changing rows per page
  };

  const unusedItems = useMemo(() => {
    return tokens?.filter(item => !item?.used) || []
  }, [tokens])

  return (
    <Stack
      direction={"column"}
      gap={2}
      flexWrap={"wrap"}
      sx={{width: "100%", py: 2, marginTop: "10px", marginBottom: '20px'}}
    >
      <AddTokensModal survey={survey} open={addTokens} handleClose={() => setAddTokens(false)} />
      <Typography variant={'h4'}>One-time survey links</Typography>
      <Typography variant={'body1'}>Generate survey links that can be completed only once.
        You can use mail merge to send these en-masse to your desired crowd. </Typography>


      <Stack sx={{direction: {xs: "column", sm: "row"}, alignItems: {xs: "flex-start", sm: "center"} }} gap={2}>
        <Button
          className={"gradient-outlined-btn"} sx={{border: 0, px: 2}}
          startIcon={<AutoFixHigh/>} onClick={() => setAddTokens(true)}
        >
          Generate Tokens
        </Button>
        <Button
          className={"gradient-outlined-btn"} sx={{border: 0, px: 2, color: Object.keys(selectedTokens).length === 0 ? `${grey[400]} !important` : ""}}
          onClick={downloadTokens} startIcon={<Download/>} disabled={Object.keys(selectedTokens).length === 0}
        >
          Download CSV
        </Button>
        <Button
          className={"gradient-outlined-btn"} sx={{border: 0, px: 2, color: Object.keys(selectedTokens).length === 0 ? `${grey[400]} !important` : ""}}
          onClick={() => deleteTokens(Object.keys(selectedTokens))} startIcon={<DeleteOutline/>} disabled={Object.keys(selectedTokens).length === 0}
        >
          Delete
        </Button>
      </Stack>

      <Stack direction={"column"} gap={1} justifyContent={"space-between"} sx={{px: 2, borderRadius: 5, minHeight: "50dvh"}} className={"border-shadow"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding={"checkbox"} sx={{fontWeight: 600}}>
                <Checkbox
                  checked={unusedItems?.length && unusedItems?.length === Object.keys(selectedTokens)?.length}
                  onChange={(e, checked) => {
                    if (unusedItems?.length === Object.keys(selectedTokens)?.length) {
                      setSelectedTokens({});
                    } else {
                      setSelectedTokens(prev => {
                        const newState = {}
                        for (const item of unusedItems) {
                          newState[item?.token] = true;
                        }
                        return newState;
                      })
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={{fontWeight: 600}}>Token</TableCell>
              <TableCell sx={{fontWeight: 600, display: {xs: "none", sm: "table-cell"}}}>Created</TableCell>
              <TableCell sx={{fontWeight: 600}}>Used</TableCell>
              <TableCell sx={{fontWeight: 600}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokens?.length === 0 && <TableRow><TableCell colSpan={5}>There is no tokens for this survey please generate
              some</TableCell></TableRow>}
            {tokens?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => {
              const isSelected = selectedTokens[item?.token] || false;
              return (
                <TableRow key={item?.token}>
                  <TableCell padding={"checkbox"}>
                    <Checkbox
                      checked={isSelected}
                      disabled={item?.used}
                      onChange={(e, checked) => {
                        setSelectedTokens(prev => {
                          const newState = {...prev};
                          if (checked) {
                            newState[item?.token] = true;
                          } else if (selectedTokens[item?.token]) {
                            delete newState[item?.token];
                          }

                          return newState;
                        })
                      }}
                    />
                  </TableCell>
                  <TableCell>{item?.token}</TableCell>
                  <TableCell sx={{display: {xs: "none", sm: "table-cell"} }}>{moment(item?.dateCreated)?.format("DD MMM YYYY HH:mm")}</TableCell>
                  <TableCell><Chip color={item?.used ? "success" : "error"}
                                   label={item?.used ? "Used" : "Not used"}/></TableCell>
                  <TableCell sx={{width: 0}}>
                    <Stack direction={"row"} gap={1}>
                      <IconButton onClick={() => copyTokenUrl(item)} disabled={item?.used}>
                        <CopyAll/>
                      </IconButton>
                      <IconButton onClick={() => deleteTokens([item?.token])} disabled={item?.used}>
                        <DeleteOutline/>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        {/* Pagination */}
        <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"} sx={{mt: "auto", gap: {xs: 0, sm: 2} }}>
          <Typography variant={"body2"}>
            Selected Tokens: {Object.keys(selectedTokens)?.length}
          </Typography>
          <TablePagination
            component={"div"}
            count={tokens.length}
            page={page} rowsPerPageOptions={[6, 10, 25, 100]}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Stack>

    </Stack>
  )
}