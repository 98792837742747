import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {grey} from "@mui/material/colors";


export const LongSelect = ({
                             id,
                             attribute,
                             title,
                             labelId,
                             variant,
                             label,
                             menuItems,
                             selectedOption,
                             setSelectedOption,
                             disabled = false,
                             size = "medium"
                           }) => {
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <FormControl variant={variant || "standard"} size={size}  sx={{minWidth: 120, width: '100%'}} fullWidth>
      <InputLabel id={id} shrink={true}>{title}</InputLabel>
      <Select
        attribute={attribute}
        labelId={labelId}
        id={id}
        value={selectedOption || null}
        sx={{bgcolor: "transparent", "&:hover": {bgcolor: grey[100]}}}
        onChange={(e) => handleChange(e)}
        label={label}
        disabled={disabled}
      >
        {
          menuItems.map((item, index) => (
            <MenuItem key={`menu-item-select-${item}-${index}`} sx={{wordBreak: "break", textWrap: "wrap"}}
                      value={item.value}>{item.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}
