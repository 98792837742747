import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import {Stack} from "@mui/material";
import SurveyList from "../../components/shared-components/SurveyList";

export const Surveys = () => {
  const [viewAllProjects, setViewAllProjects] = useState(false);
  const {
    state: { surveys },
  } = useContext(AppContext);

  return (
    <Stack direction={"column"} maxWidth={"lg"} sx={{mx: {xs: 2, md: "auto"}}}>
      <SurveyList
        surveys={surveys}
        viewAllProjects={viewAllProjects}
        setViewAllProjects={setViewAllProjects}
      />
    </Stack>
  );
};
