import {useAtom} from "jotai";
import {CreateSurveyQuestionsState, initialSurvey} from "./atoms";
import {useNavigate, useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import {useSaveSurvey} from "./hooks/useSaveSurvey";
import {useEffect} from "react";
import {collection, doc, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {SurveyInformation} from "./components/survey-information";
import {SurveyQuestions} from "./components/survey-questions";
import {SurveySettingsModal} from "./components/survey-settings-modal";
import {CreateQuestionModal} from "./components/create-question-modal";
import {animals, colors, uniqueNamesGenerator} from "unique-names-generator";
import {v4 as uuid} from "uuid";


export const CreateSurveyNew = () => {
  const {id} = useParams();
  const {survey, setSurvey} = useSaveSurvey();
  const [questions, setQuestions] = useAtom(CreateSurveyQuestionsState);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const unsubscribe = onSnapshot(doc(db, "surveys", id), (doc) => {
        const newSurvey = doc.data() || {};
        if (newSurvey && newSurvey?.uid) {
          console.log("Survey exist and updated...", doc.data())
          setSurvey(newSurvey);
          console.log("Updated");
        }
      }, () => {
        console.log("Survey not exist initialize name and id")
      })
      return () => unsubscribe()
    } else {
      const newUid = uuid();
      const name = uniqueNamesGenerator({
        dictionaries: [colors, animals],
        style: "lowerCase",
        separator: "-",
      })
      setSurvey({...initialSurvey, uid: newUid, surveyName: name});
      setQuestions([])
      console.log("Survey and questions initialization")
      navigate(`/create-survey/${newUid}`)
    }
  }, [id])

  useEffect(() => {
    if (survey?.uid) {
      const surveyUid = id || survey?.uid;
      const customQuery = query(collection(db, "questions"), where('surveyUid', '==', surveyUid));
      const unsubscribe = onSnapshot(customQuery, (querySnapshot) => {
        let newQuestions = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newQuestions.push(data);
        });
        setQuestions(newQuestions);
      });

      return () => unsubscribe();
    }
  }, [survey?.uid])

  return (
    <Stack direction={"column"} gap={2} maxWidth={"lg"} sx={{mx: {xs: 0, md: "auto"}, px: 2, py: {xs: 2, md: 5} }}>
      <SurveySettingsModal />
      <CreateQuestionModal />
      <SurveyInformation />
      <SurveyQuestions/>
    </Stack>
  )
}