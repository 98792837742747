import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import React, {useState} from "react";
import Camera, {FACING_MODES, IMAGE_TYPES} from "react-html5-camera-photo";
import {toast} from "react-toastify";
import {CircularProgress, Grid, IconButton} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {DeleteOutline} from "@mui/icons-material";
import Box from "@mui/material/Box";
import 'react-html5-camera-photo/build/css/index.css';
import {removeFile, uploadFile} from "../../../utils/functions";
import {v4 as uuid} from "uuid";
import {IconRefresh} from "@tabler/icons-react";

export const ImageQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);
  const [loading, setLoading] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODES.USER);

  const onTakePhoto = async (dataUri) => {
    if (loading) {
      return;
    }
    if (answer?.photos && answer?.photos?.length >= 3) {
      toast.error("Cannot add more than 3 photos")
      return;
    }

    console.log(dataUri);
    setAnswer(prev => {
      const photos = prev?.photos ? [...prev?.photos] : [];
      photos.push(dataUri);
      return {
        ...prev,
        photos: photos
      }
    })
  }

  const onRemovePhoto = async (photo) => {
    try {
      setLoading(true);
      await removeFile(photo);
    } catch (error) {
      console.log("Failed to remove photo:", photo)
      console.error(error);
    } finally {
      setLoading(false);
    }
    setAnswer(prev => ({...prev, photos: prev?.photos?.filter(i => i !== photo)}));
  }

  const onSelectImageFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const dataURI = e.target.result;
      await onTakePhoto(dataURI);
    };
    reader.readAsDataURL(file);
  }

  function onCameraError(error) {
    console.error('onCameraError', error);
  }

  function onCameraStart(stream) {
    console.log('onCameraStart');
  }

  function onCameraStop() {
    console.log('onCameraStop');
  }

  return (
    <>
      <QuestionTitle title={answer?.question || "-"} />
      <Box>
        <Box maxWidth={"sm"} sx={{position: "relative", mx: "auto"}}>
          {loading && <Box sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100000000}}><CircularProgress /></Box>}
          <Camera
            onTakePhoto={(dataUri) => onTakePhoto(dataUri)}
            onCameraError={onCameraError}
            style={{ width: "100% !important", height: "auto" }}
            idealFacingMode={facingMode}
            idealResolution={{width: 350, height: 350}}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.97}
            isMaxResolution={false}
            isImageMirror={facingMode === FACING_MODES.USER}
            isDisplayStartCameraError={true}
            sizeFactor={1}
            onCameraStart={onCameraStart}
            onCameraStop={onCameraStop}
          />
          <IconButton
            component="label"
            size={'large'} disabled={loading}
            sx={{position: "absolute", bottom: 0, left: 0, transform: "translate(25%, -30%)", bgcolor: "rgba(255,255,255,0.1)", color: 'white'}}
          >
            <AddPhotoAlternateIcon/>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={onSelectImageFile}
            />
          </IconButton>
          <IconButton
            size={'large'} disabled={loading}
            onClick={() => setFacingMode(prev => prev === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT)}
            sx={{position: "absolute", bottom: 0, right: 0, transform: "translate(-25%, -30%)", bgcolor: "rgba(255,255,255,0.1)", color: 'white'}}
          >
            <IconRefresh/>
          </IconButton>
        </Box>

        <Grid container justifyContent={'center'} sx={{marginTop: 2}}>
          {answer?.photos?.map((photo) => {
            return (
              <Grid key={photo} item sx={{position: 'relative', display: 'inline-flex', margin: 0.5}}>
                {loading && <Box sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100000000}}><CircularProgress size={15} /></Box>}
                <img src={photo} style={{width: 90, height: 90, filter: loading ? "grayscale(0.9)" : "unset"}} alt={`preview img`}/>
                {loading === false && <IconButton
                  sx={{
                    color: 'white',
                    position: "absolute",
                    top: "0", right: "0",
                    transform: "translate(-3px, 3px)",zIndex: 1,
                    bgcolor: "rgba(255,255,255,0.1)",
                    backdropFilter: "blur(4px)",
                  }}
                  size={"small"}
                  disabled={loading}
                  onClick={() => onRemovePhoto(photo)}
                >
                  <DeleteOutline fontSize={"small"}/>
                </IconButton>}
              </Grid>)
          })}
        </Grid>
      </Box>
    </>
  )
}