import {AnswerAtom, PrefetchedImagesAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import React from "react";
import {Box, Slide, Typography} from "@mui/material";
import {useAtomValue} from "jotai";

export const SectionQuestion = () => {
    const answer = useAtomValue(AnswerAtom);
    const images = useAtomValue(PrefetchedImagesAtom);

    return (
        <>
            <QuestionTitle title={answer?.question || "-"}/>
            <Typography align={"left"} fontWeight={400} fontSize={"16px"} lineHeight={"1.3"} color={"#888888"}>
              {answer?.subTitle}
            </Typography>
            <Slide direction={"right"} in={!!answer?.imgUrl} timeout={1000}>
                <Box sx={{maxHeight: "45dvh", textAlign: 'center'}}>
                    <img src={images[answer?.imgUrl]?.src || answer?.imgUrl} alt={answer?.title} width={"100%"} height={"auto"}
                         style={{objectFit: "contain", maxHeight: "45dvh"}}/>
                </Box>
            </Slide>
            <Typography
              component={answer?.link ? "a" : "p"}
              href={answer?.link || undefined}
              target={answer?.link ? "_blank" : undefined}
              align={"center"} fontWeight={500} fontSize={"0.9rem"}
            >
              {answer?.bottomExplainer || answer?.text}
            </Typography>
        </>
    )
}
