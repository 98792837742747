import {useSaveSurvey} from "../hooks/useSaveSurvey";
import {Button, IconButton, Input, Stack, Tooltip, Typography} from "@mui/material";
import {useAtom, useSetAtom} from "jotai";
import {CreateSurveySettingsState, SelectedLanguageState} from "../atoms";
import React, {useEffect, useMemo, useState} from "react";
import {ChevronLeft, ChevronRight, Close, Save, Settings} from "@mui/icons-material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import ViewIcon from "../../../assets/icons/view-survey.png";
import {animals, colors, uniqueNamesGenerator} from "unique-names-generator";


export const SurveyInformation = () => {
  const {survey, saveOnChange, setSurvey, saveSurvey} = useSaveSurvey();
  const [selectedLanguage, setSelectedLanguage] = useAtom(SelectedLanguageState);
  const [defaultName, setDefaultName] = useState("");
  const setOpenSettings = useSetAtom(CreateSurveySettingsState);

  useEffect(() => {
    if (!survey?.surveyName && !survey?.uid) {
      const name = uniqueNamesGenerator({
        dictionaries: [colors, animals],
        style: "lowerCase",
        separator: "-",
      })
      setDefaultName("");
      setSurvey(prev => ({...prev, surveyName: name}))
    } else {
      setDefaultName(survey?.surveyName || "");
    }
  }, [])

  useEffect(() => {
    if (survey?.languages && survey?.languages?.length) {
      const isExisting = survey?.languages?.find(i => i?.BCP47 === selectedLanguage?.BCP47);
      if (!isExisting) {
        setSelectedLanguage(survey?.languages[0] || null)
      }
    }
  }, [selectedLanguage, survey?.languages])

  useEffect(() => {
    if (selectedLanguage === null && survey?.languages?.length) {
      setSelectedLanguage(survey.languages[0]);
    }
  }, [selectedLanguage])


  const onSelectLanguage = (index) => {
    const language = survey?.languages[index] || null;

    if (language) {
      setSelectedLanguage(language);
    }
  }

  const onChange = e => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    if (key) {
      onUpdateSurvey(key, value);
    }
  }

  const onUpdateSurvey = (key, value) => {
    setSurvey(prev => ({...prev, [key]: value}));
  }

  const handleOnBlur = () => {
    if (saveOnChange) {
      saveSurvey();
    }
  }

  const index = useMemo(() => {
    return survey?.languages?.findIndex(item => item?.BCP47 === selectedLanguage?.BCP47) || 0;
  }, [selectedLanguage, survey?.languages])

  return (
    <>
      <Stack gap={1}
             alignItems={"flex-start"}
             justifyContent={"space-between"}
             sx={{flexDirection: {xs: "column-reverse", sm: "row"},}}
             useFlexGap flexWrap={"wrap"}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mx: 'auto'}}>
          <IconButton
            disabled={index === 0}
            onClick={() => onSelectLanguage(index - 1)}
          >
            <ChevronLeft/>
          </IconButton>
          <Typography variant={"h6"} color={"info.main"}>{selectedLanguage?.Native}</Typography>
          <IconButton
            disabled={index === survey?.languages?.length - 1}
            onClick={() => onSelectLanguage(index + 1)}
          >
            <ChevronRight/>
          </IconButton>
        </Stack>
        <Box sx={{flex: 1}}>
          <Stack direction={"row"} gap={1} justifyContent={"flex-end"} alignItems={"center"}>
            <Tooltip title={"Settings"}>
              <IconButton color={"info"} onClick={() => setOpenSettings(true)}>
                <Settings/>
              </IconButton>
            </Tooltip>
            <Tooltip title={"View live survey"}>
              <IconButton
                variant={"contained"}
                size={"small"}
                disabled={!survey?.uid}
                component={"a"}
                href={window?.location?.origin + "/survey/" + survey.uid}
                target={"_blank"}
              >
                <img
                  src={ViewIcon}
                  alt={"View survey"}
                  style={{width: "24px", filter: survey?.uid ? undefined : "grayscale(100%)"}}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Save Survey"}>
              <Button
                color={"info"} size={"large"}
                startIcon={<Save/>}
                variant={"text"}
                sx={{borderRadius: 3, px: 2}}
                onClick={() => saveSurvey()}
              >
                Save
              </Button>
            </Tooltip>
          </Stack>
          {survey?.dateUpdated &&
            <Typography variant={"body2"} align={"right"}>Last saved: {moment(survey?.dateUpdated).fromNow(true)}</Typography>}
        </Box>
      </Stack>
      <Input
        value={survey?.surveyName}
        name={"surveyName"}
        onChange={onChange}
        onBlur={handleOnBlur}
        sx={{
          fontSize: "1.5rem",
          color: "primary.main",
          my: 2,
          maxWidth: "sm"
        }}
        endAdornment={<IconButton onClick={() => onUpdateSurvey("surveyName", "")}><Close/></IconButton>}
        placeholder={defaultName || "Enter your survey name"}
      />
    </>
  )
}