import {Fab, Fade, Typography} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import React, {useRef, useState, useEffect} from "react";
import {useSetAtom} from "jotai/index";
import {CreateQuestionModalState} from "../atoms";


export const CreateQuestionButton = () => {
  const containerRef = useRef();
  const [isHovered, setIsHovered] = useState(true);
  const setCreateQuestion = useSetAtom(CreateQuestionModalState);

  useEffect(() => {
    const updateHoverState = () => {
      if (window.innerWidth > 768) {
        console.log("isHovered", isHovered);
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    };

    updateHoverState();

    window.addEventListener('resize', updateHoverState);

    return () => window.removeEventListener('resize', updateHoverState);
  }, []);
const stylesHovered = {
    position: "fixed",
    bottom: 10, right: 20,
    minWidth: 50, height: 50,
    p: 2, borderRadius: "50%",
    borderRadius: 7,
    borderBottomRightRadius: 7,
    ".text": {
      display: "block",
      opacity: 1,
      visibility: "visible",
      transition: "opacity 2s ease-in-out, visibility 2s",
    }
  }
  const stylesNotHovered = {
      position: "fixed",
      bottom: 10, right: 20,
      minWidth: 50, height: 50,
      p: 2, borderRadius: "50%",
      "&:hover": {
        borderRadius: 7,
        borderBottomRightRadius: 7
      },
      "&:hover > .text": {
        display: "block",
        opacity: 1,
        visibility: "visible",
        transition: "opacity 2s ease-in-out, visibility 2s",
      }, 
  }
  return (
    <Fab
      sx={isHovered ? stylesHovered : stylesNotHovered}
      ref={containerRef}
      onClick={() => setCreateQuestion(true)}
      variant="extended"
      size="medium"
      color="primary"
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={"bg-gradient"}
    >
      <IconPlus sx={{ mr: 1 }} />
      <Fade in={isHovered} timeout={400}>
        <Typography
          className={"text"}
          sx={{display: "none", color: "inherit", visibility: "hidden", opacity: 0}}
        >
          Add Question
        </Typography>
      </Fade>
    </Fab>
  )
}