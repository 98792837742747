import {grey} from "@mui/material/colors";
import {Button, IconButton, Stack, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import {
  Add,
  ChevronRight,
  DeleteOutline,
  DriveFileRenameOutline,
  ExpandMore,
  QrCodeScanner
} from "@mui/icons-material";
import moment from "moment";
import React, {useState} from "react";
import {ShareQrCodeModal} from "../../components/survey-qrcode/ShareQrCodeModal";

export const SurveyReportItem = ({
                                   survey,
                                   deleteReport,
                                   onCreateReport,
                                   onEditReport
                                 }) => {
  const [open, setOpen] = useState(false);
  const [shareReport, setShareReport] = useState(null);

  return (
    <>
      <ShareQrCodeModal
        open={!!shareReport}
        handleClose={() => setShareReport(null)}
        urlToDisplay={`${window.location.origin}/report/${shareReport?.uid}`}
        title={shareReport?.name}
        subtitle={"By sharing this QR code/link, you are sharing the report with the public."}
      />
      <TableRow key={survey?.uid} hover sx={{bgcolor: survey?.archived ? grey[100] : "transparent"}}>
        <TableCell sx={{textAlign: 'center'}}>
          <IconButton sx={{mx: "auto"}} color={"primary"} onClick={() => setOpen(prev => !prev)} disabled={survey?.reports?.length === 0}>
            {open ? <ExpandMore/> : <ChevronRight/>}
          </IconButton>
        </TableCell>
        <TableCell>{survey?.surveyName}</TableCell>
        <TableCell>
          <Tooltip title={survey?.languages?.map(language => language?.Native)?.splice(0, 3)?.join(", ")}>
            <Typography>{survey?.languages?.map(language => language?.Native)?.splice(0, 2)?.join(", ") || "N/A"}{survey?.languages?.length >= 3 && "..."}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{moment(survey?.dateCreated).format("DD MMM YYYY HH:mm")}</TableCell>
        <TableCell>
          <Button
            className={"gradient-outlined-btn"} sx={{border: 1, px: 2}} startIcon={<Add/>}
            onClick={() => onCreateReport()}
          >
            Create Report
          </Button>
        </TableCell>
        {/*<TableCell>{<Chip color={survey?.archived ? "error" : "success"} label={survey?.archived ? "Not Active" : "Active"} />}</TableCell>*/}
      </TableRow>
      {open && survey?.reports?.map((report, index) => {
        return (
          <TableRow key={report?.uid}>
            <TableCell sx={{color: grey[800], textAlign: "center"}}>
              {index + 1}.
              {/*<InsightsOutlined/>*/}
            </TableCell>
            <TableCell>{report?.name}</TableCell>
            <TableCell>Total Questions: {report?.questions?.length}</TableCell>
            <TableCell>{moment(report?.dateCreated).format("DD MMM YYYY HH:mm")}</TableCell>
            <TableCell>
              <Stack direction={"row"} gap={1}>
                <Tooltip title={"View and Share Report"}>
                  <IconButton
                    color={"primary"}
                    onClick={() => setShareReport(report)}
                  >
                    <QrCodeScanner/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Edit Report"}>
                  <IconButton onClick={() => onEditReport(report)}>
                    <DriveFileRenameOutline/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Delete Report"}>
                  <IconButton onClick={() => deleteReport(report.uid)}>
                    <DeleteOutline/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        )
      })}
    </>

  )
}