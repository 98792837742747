import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import {FormControlLabel, RadioGroup} from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

export const CheckBoxQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  const onSelect = (option) => {
    setAnswer(prev => {
      const options = prev?.options?.map(i => ({...i, checked: i?.option === option ? !i?.checked : i?.checked}))
      return {
      ...prev,
        text: options?.filter(i => i?.checked)?.map(i => i?.option)?.join(", "),
        options: options
      }
    })
  }

  return (
    <>
      <QuestionTitle title={answer?.question || "-"} />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        {answer?.options?.map(item => {
          return (
            <FormControlLabel value={item?.option}
                              control={<Checkbox checked={item?.checked}/>}
                              checked={item?.checked}
                              label={item?.option}
                              key={item.option}
                              onChange={(e) => onSelect(item?.option)}/>
          )
        })}
      </RadioGroup>
    </>
  )
}